<template>
  <div>
    <div>
    </div>

    <v-dialog
        style="z-index: 99999"
        v-model="dialog"
        width="697px"
        max-width="697px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
            v-bind="attrs"
            v-on="on"
        >
          <slot name="button"></slot>
        </div>
      </template>
      <v-btn @click="dialog = false" class="close-comment-dialog"><v-icon color="#a8a8a8">mdi-close</v-icon></v-btn>
      <v-card style="border-radius: 15px;">
        <v-alert
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
            style="position: fixed; z-index: 99999; left: 0; margin-left: 300px; margin-right: 300px; right: 0"
        >{{ NOTIFICATION }}
        </v-alert>
        <v-card-title>
          <span class="comment-card-title">Report Type</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group
              style="margin-top: 0!important; padding-top: 0!important; margin-bottom: 40px!important"
              v-model="type"
              column
              hide-details
          >
            <v-radio
                label="Content"
                color="#6d6d6d"
                value="content"
            ></v-radio>
            <v-radio
                label="Technical"
                color="#6d6d6d"
                value="technical"
            ></v-radio>
            <v-radio
                label="Other"
                color="#6d6d6d"
                value="other"
            ></v-radio>
          </v-radio-group>
          <v-textarea
              placeholder="Report"
              color="black"
              outlined
              rounded
              no-resize
              height="176px"
              v-model="text"
              hide-details>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn @click="storeReport" :disabled="!(text && type)" class="comment-green-btn" rounded>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Report",

  props: ["entity_id", "entity_type"],

  data() {
    return {
      dialog: false,
      type: null,
      text: null,
    }
  },

  methods: {
    storeReport() {
      window.axios.post(`api/content-report`, {
        type: this.type,
        text: this.text,
        entity_id: this.entity_id,
        entity_type: this.entity_type
      }).then(() => {
        this.NOTIFICATION = 'Item Reported Successfully!';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'green'
        setTimeout(() => {
          this.dialog = false
        }, 2000)
      })
    },
  }
}
</script>

<style scoped>
.v-card {
  background-color: #f2f3f7;
}

.v-card__title {
  padding-top: 50px!important;
  padding-bottom: 30px!important;
  padding-left: 79px!important;
  padding-right: 79px!important;
}
.v-card__text {
  padding-bottom: 40px!important;
  padding-left: 79px!important;
  padding-right: 79px!important;
}
.v-card__actions {
  padding-bottom: 60px!important;
  padding-left: 79px!important;
  padding-right: 79px!important;
}

.comment-card-title {
  font-family: Blinker-SemiBold;
  color: #6d6d6d;
  font-size: 16px;
}

.comment-green-btn {
  background-color: #48b284!important;
  opacity: 100!important;
  box-shadow: none!important;
  font-size: 16px!important;
  font-family: Blinker-SemiBold!important;
  color: #ffffff!important;
  width: 180px!important;
  height: 60px!important;
}

.yellow-green-btn {
  background-color: #fdbd68!important;
  opacity: 100!important;
  box-shadow: none!important;
  font-size: 16px!important;
  font-family: Blinker-SemiBold!important;
  color: #ffffff!important;
  width: 180px!important;
  height: 60px!important;
  margin-left: 18px;
}

.close-comment-dialog {
  background-color: #f2f3f7!important;
  width: 50px!important;
  min-width: 50px!important;
  height: 50px!important;
  padding-right: 0!important;
  padding-left: 0!important;
  border-radius: 10px!important;
  box-shadow: none;
  position: absolute;
  right: calc(((100vw - 697px) / 2) - 70px);
}
</style>